import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Grid,
  Link,
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  extendTheme,
  Image,
  SimpleGrid,
  Center,
  Icon
} from '@chakra-ui/react';
import { MdSecurity, MdMemory, MdPublic } from 'react-icons/md';
import { useForm, ValidationError } from '@formspree/react';

const theme = extendTheme({
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Montserrat', sans-serif",
  },
});

const NavLink = ({ href, children }) => (
  <Link href={href} color="gray.300" _hover={{ color: "white" }}>
    {children}
  </Link>
);

const DataTypeTab = ({ label, isActive, onClick }) => (
  <Box
    as="button"
    px={6}
    py={2}
    borderRadius="full"
    bg={isActive ? "white" : "whiteAlpha.200"}
    color={isActive ? "black" : "white"}
    _hover={{ bg: isActive ? "white" : "whiteAlpha.300" }}
    onClick={onClick}
  >
    {label}
  </Box>
);

const DataLabelingSection = () => {
  const [activeTab, setActiveTab] = useState("EO");
  
  const dataTypes = ["EO", "SAR", "Lidar", "Infared (IR)", "Radio frequency (RF)", "Sonar"];
  
  return (
    <Section id="data-labeling" bg="black">
      <VStack spacing={8} align="flex-start">
        <VStack spacing={2} align="flex-start" w="full">
          <Heading as="h2" size="2xl" color="white">
            Data Labeling
          </Heading>
          <Text fontSize="xl" color="gray.400">
            We specialize in high-quality data labeling for defense applications.
          </Text>
        </VStack>
        
        <HStack spacing={4} overflowX="auto" w="full" pb={2}>
          {dataTypes.map((type) => (
            <DataTypeTab
              key={type}
              label={type}
              isActive={activeTab === type}
              onClick={() => setActiveTab(type)}
            />
          ))}
        </HStack>
        
        <Flex direction={{ base: 'column', lg: 'row' }} spacing={8} w="full">
          <Box flex={1} pr={{ lg: 12 }} mb={{ base: 8, lg: 0 }}>
            <Text fontSize="lg" color="gray.300" mb={8}>
            Argus Systems is the AI data labeling platform for defense, 
            handling complex sensor data like satellite imagery (EO, hyperspectral, IR, SAR), 
            lidar, RF, and sonar. We streamline and scale labeling with AI-driven automation 
            and human expertise, ensuring accuracy and efficiency. Our mission is to be the 
            trusted partner for defense data labeling across all domains.
            </Text>
            <Button
              as="a"
              href="#contact"
              size="lg"
              bg="white"
              color="black"
              _hover={{ bg: "gray.200" }}
              leftIcon={<Icon as={MdSecurity} />}
            >
              Label My Data
            </Button>
          </Box>
          <Box flex={1}>
            <Image
              src={`/images/${activeTab === "Radio frequency (RF)" ? "rf" : 
                          activeTab === "Infared (IR)" ? "infared" :
                          activeTab.toLowerCase().replace(/\s+|\(|\)/g, '')}.png`}
              alt={`${activeTab} Data Labeling Demo`}
              borderRadius="lg"
              objectFit="cover"
              w="full"
              h="400px" // Fixed height for consistent sizing
              objectPosition="center" // Center the image within the fixed dimensions
            />
          </Box>
        </Flex>
      </VStack>
    </Section>
  );
};

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mpwajkge");
  const toast = useToast();

  React.useEffect(() => {
    if (state.succeeded) {
      toast({
        title: "Message sent.",
        description: "Thanks for reaching out! We'll get back to you soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [state.succeeded, toast]);

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel htmlFor="email" color="gray.700">Email Address</FormLabel>
          <Input 
            id="email" 
            type="email" 
            name="email" 
            color="black"
            _placeholder={{ color: 'gray.500' }}
            bg="white"
            borderColor="gray.300"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="message" color="gray.700">Message</FormLabel>
          <Textarea 
            id="message" 
            name="message" 
            color="black"
            _placeholder={{ color: 'gray.500' }}
            bg="white"
            borderColor="gray.300"
          />
          <ValidationError prefix="Message" field="message" errors={state.errors} />
        </FormControl>
        <Button type="submit" colorScheme="blue" isLoading={state.submitting}>
          Send Message
        </Button>
      </VStack>
    </Box>
  );
};

const Section = ({ id, title, children, bg }) => {
  const titleColor = bg === "black" ? "white" : "black";
  return (
    <Box id={id} py={20} bg={bg}>
      <Container maxW="container.xl">
        <Heading as="h2" size="xl" mb={8} color={titleColor}>
          {title}
        </Heading>
        {children}
      </Container>
    </Box>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <Box bg="gray.800" p={6} borderRadius="lg" boxShadow="lg">
    <Icon as={icon} w={12} h={12} color="white" mb={4} />
    <Heading as="h3" size="md" mb={2} color="white">
      {title}
    </Heading>
    <Text color="gray.300">{description}</Text>
  </Box>
);

const FontLoader = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return null;
};

const NewsClip = ({ logo, content }) => (
  <VStack align="center" spacing={4} minW="250px">
    <Center w="150px" h="75px">
      <Image 
        src={logo} 
        alt="Company Logo" 
        w="150px"
        h="75px"
        objectFit="contain"
      />
    </Center>
    <Text fontSize="sm" color="gray.700" textAlign="center" w="250px">
      {content}
    </Text>
  </VStack>
);

const NewsSection = () => (
  <Section id="news" title="Latest News" bg="gray.100">
    <Box>
      <HStack spacing={4} justify="space-between">
        <NewsClip
          logo="/images/vit.png"
          content="Argus Systems wins 2nd place and $10,000 at the Vets in Tech pitch competition at a16z in San Francisco."
        />
        <NewsClip
          logo="/images/pear.png"
          content="Pear VC invites Argus Systems to join its Founders-In-Residence Program for Summer 2024."
        />
        <NewsClip
          logo="/images/mbafund.png"
          content="Argus Systems wins the Harvard MBA Fund startup competition and a $25,000 investment prize."
        />
        <NewsClip
          logo="/images/hbs.png"
          content="Argus Systems is selected for the HBS's Rock Entrepreneurship Fellowship and will receive experimentation funds for Summer 2024."
        />
      </HStack>
    </Box>
  </Section>
);

export default function ArgusSystems() {
  return (
    <ChakraProvider theme={theme}>
      <FontLoader />
      <Box bg="gray.900" color="white">
        <Box
          as="header"
          py={4}
          position="absolute"
          top={0}
          left={0}
          right={0}
          zIndex={10}
        >
          <Container maxW="container.xl">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading as="h1" size="lg" color="white" letterSpacing="0.1em">
                ARGUS SYSTEMS
              </Heading>
              <Flex alignItems="center" gap={8}>
                <Button 
                  as="a" 
                  href="#contact" 
                  bg="white" 
                  color="black" 
                  _hover={{ bg: "gray.200" }} 
                  borderRadius="full" 
                  px={6}
                >
                  Contact
                </Button>
              </Flex>
            </Flex>
          </Container>
        </Box>

        <Box as="main">
          <Box id="home" height="100vh" position="relative" overflow="hidden">
            <Box
              as="video"
              autoPlay
              loop
              muted
              playsInline
              position="absolute"
              width="100%"
              height="100%"
              objectFit="cover"
              src="/videos/combined_20s.mp4"
            />
            <Flex
              position="absolute"
              inset={0}
              bg="blackAlpha.600"
              alignItems="center"
              justifyContent="center"
            >
              <Container maxW="container.xl">
                <VStack align="center" spacing={6} p={8} maxW="container.lg" mx="auto">
                  <Heading 
                    as="h1" 
                    size="4xl" 
                    lineHeight={1.2} 
                    color="white" 
                    textAlign="center"
                    fontWeight="bold"
                    letterSpacing="wide"
                    textShadow="2px 2px 4px rgba(0,0,0,0.5)"
                  >
                    <Text 
                      as="span" 
                      display="block" 
                      fontSize="6xl"
                      color="white"
                      mb={4}
                      textTransform="uppercase"
                      letterSpacing="0.1em"
                    >
                      Better data.
                    </Text>
                    <Text 
                      as="span" 
                      display="block"
                      fontSize="6xl"
                      color="white" 
                      mb={4}
                      textTransform="uppercase"
                      letterSpacing="0.1em"
                    >
                      Smarter defense.
                    </Text>
                    <Text 
                      as="span"
                      display="block"
                      fontSize="6xl"
                      color="white"
                      textTransform="uppercase"
                      letterSpacing="0.1em"
                    >
                      A stronger nation.
                    </Text>
                  </Heading>
                  <Button
                    as="a" 
                    href="#contact"
                    size="lg"
                    bg="white"
                    color="black"
                    mt={8}
                    px={12}
                    py={7}
                    fontSize="xl"
                    fontWeight="bold"
                    borderRadius="full"
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                      bg: 'gray.100'
                    }}
                    transition="all 0.3s ease"
                  >
                    Book a demo
                  </Button>
                </VStack>
              </Container>
            </Flex>
          </Box>
          <DataLabelingSection />
          <NewsSection />
          <Section id="contact" title="Want to learn more?" bg="white">
            <Text fontSize="lg" mb={6} color="black">
              Reach out to the Argus Systems team today.
            </Text>
            <ContactForm />
          </Section>
        </Box>

        <Box as="footer" bg="black" py={8} color="white">
          <Container maxW="container.xl" textAlign="center">
            <Text>&copy; 2025 Argus Systems. All rights reserved.</Text>
          </Container>
        </Box>
      </Box>
    </ChakraProvider>
  );
}